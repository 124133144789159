import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { Form, Formik } from "formik";
import _ from "lodash";
import { Fragment, useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import { axiosInstance } from "../../services/AxiosInstance";
import { InviteClient } from "../../swagger";
import getUserInfoFromInviteToken from "../../swr/getUserInfoFromInviteToken";

const ChangePassword = (props) => {
  const { t } = useTranslation("identity.");
  const [showPassword, setShowPassword] = useState(false);
  const authResult = new URLSearchParams(window.location.search);
  const token = authResult.get("token");
  const [loading, setLoading] = useState(false);
  const { users, isLoadingUsersApi, isErrorUsersApi } =
    getUserInfoFromInviteToken(token);

  console.log('users', users);

  const newUser = !_.isEmpty(users) ? users[0] : null;
  const inviteUser = {
    name: !_.isEmpty(users) ? users[1].firstName : "",
    email: !_.isEmpty(users) ? users[1].email : "",
  };

  const changePass = async (values) => {
    console.log("pass", values);
    setLoading(true);
    const client = new InviteClient(undefined, axiosInstance);
    try {
      var res: any = null;
      res = await client.changePasswordFromInviteToken(token, values.password);

      if (res && res.succeeded) {
        window.location.replace("/account/login");

        setLoading(false);
        // TokenStorage.storeToken(res.data. as string);
        // TokenStorage.storeRefreshToken(res.refresh_token as string);
        // window.location.replace("/");
      } else {
        setLoading(false);
        toast.error(_.first(res.errors) as any);
        // addToast(res.error, { appearance: "error" });
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        t(
          "identity.src.components.login.EnterEmail.somethingUnexpectedHappenedPleaseTryAgainLater"
        )
      );
      // addToast("Server Error Occured", { appearance: "error" });
    }
    setLoading(false);
  };

  const checkValidate = (values) => {
    let error = {} as any;
    if (!values.password || values.password == "") {
      error.password = t("identity.inviteViaEmail.enterAPassword");
    } else if (values.password.length < 12) {
      error.password = t(
        "identity.src.components.login.CreatePassword.passwordMustBeAtLeast12Characters"
      );
    } else if (
      !/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&*()_+!]).*$/.test(
        values.password
      )
    ) {
      error.password = t(
        "identity.src.components.login.CreatePassword.passwordMustHaveCapitalNumbersAndSpecialCharacters"
      );
    }
    return error;
  };

  return token && newUser && inviteUser ? (
    <Fragment>
      <div className="mx-auto grid place-items-center min-h-screen">
        <div className=" self-center h-fit organizer clearfix registerFormContainer rounded overflow-visible lg:shadow-lg p-6">
          <div className="sizeHandler">
            <div className="signTop organizer clearfix">
              <div className="flex  gap-x-6 place-items-center place-content-center organizer loginForm fln m0a grid-6 grid-t-12">
                <div className="registerImage sm:block hidden border-r-2  border-grey-50 leftFloat centerFlex halfColumn">
                  <img src="/img/sign.svg" className="h-80" />
                </div>
                <div className=" rightFloat halfColumn leftBorder organizer flex flex-col gap-y-8">
                  <div className="centerKeeper textInCenter flex flex-col gap-y-5 text-center">
                    <img src="/img/Voxdash.svg" className="h-9" />
                    <p className="text-l font-medium normalDoubleBottomMargin text-gray-600">
                      {inviteUser?.name}({inviteUser?.email})
                      {t("identity.inviteViaEmail.hasInvited")}
                      {" " + newUser.email + " "}
                      {t("identity.inviteViaEmail.ToVoxdash")}
                    </p>
                    <p className="text-l font-medium normalDoubleBottomMargin text-gray-600">
                      {t(
                        "identity.inviteViaEmail.pleaseSetYourPasswordToCreateYourAccount"
                      )}
                    </p>

                    <Formik
                      onSubmit={changePass}
                      initialValues={{}}
                      validate={checkValidate}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                      }) => (
                        <Form
                          onSubmit={handleSubmit}
                          className="organizer fln m0a grid-6 grid-t-12"
                        >
                          <div className="flex flex-col gap-y-4 place-content-center">
                            <div>
                              <label
                                htmlFor="email"
                                className="block text-left text-sm font-medium leading-6 text-gray-900"
                              >
                                {t("identity.inviteUser.createPassword")}
                              </label>
                              <div className="relative">
                                <div className=" mt-2 rounded-md shadow-sm">
                                  <input
                                    type={showPassword ? "text" : "password"}
                                    name="password"
                                    className={classNames(
                                      {
                                        " text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500":
                                          errors.password,
                                      },
                                      "block w-full rounded-md border-0 py-1.5 pr-10 sm:text-sm sm:leading-6"
                                    )}
                                    aria-invalid="true"
                                    aria-describedby="email-error"
                                    value={values.password}
                                    onChange={handleChange}
                                  />

                                  <div className="z-15 absolute gap-1 inset-y-0 right-0 flex items-center pr-3">
                                    <>
                                      <svg
                                        fill="none"
                                        onClick={() =>
                                          setShowPassword(!showPassword)
                                        }
                                        className={classNames(
                                          "h-3 cursor-pointer  text-gray-700",
                                          {
                                            hidden: !showPassword,
                                            block: showPassword,
                                          }
                                        )}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 576 512"
                                      >
                                        <path
                                          fill="currentColor"
                                          d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
                                        ></path>
                                      </svg>

                                      <svg
                                        fill="none"
                                        onClick={() =>
                                          setShowPassword(!showPassword)
                                        }
                                        className={classNames(
                                          "h-3 cursor-pointer text-gray-700",
                                          {
                                            hidden: showPassword,
                                            block: !showPassword,
                                          }
                                        )}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 640 512"
                                      >
                                        <path
                                          fill="currentColor"
                                          d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"
                                        ></path>
                                      </svg>
                                      {errors.password ? (
                                        <ExclamationCircleIcon
                                          className="h-5 w-5 text-red-500"
                                          aria-hidden="true"
                                        />
                                      ) : null}
                                    </>
                                  </div>
                                </div>
                              </div>

                              {errors.password ? (
                                // && touched.userLoginEmail
                                <p className="mt-2 text-sm text-red-600 text-left">
                                  {errors.password.toString()}
                                </p>
                              ) : null}
                            </div>

                            <button
                              type="submit"
                              className="cursor-pointer text-white bg-[#00917c] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center block  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                              {t(
                                "identity.src.components.login.CreatePassword.CreateAccount"
                              )}
                              <svg
                                className="float-right flex w-5 h-5 ml-2 -mr-1"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                  clipRule="evenodd"
                                ></path>
                              </svg>
                            </button>
                            <p className="text-xs normalDoubleBottomMargin text-gray-600 space-x-1">
                              <span>
                                {t(
                                  "identity.src.components.login.CreatePassword.ByCreatingAnAcount,YouAgreeToPeopleAnalyticsAnd"
                                )}
                              </span>

                              <a
                                target="_blank"
                                href={
                                  process.env.REACT_APP_PUBLIC_URL +
                                  "/policies/Terms%20of%20Use"
                                }
                                className="cursor-pointer text-xs text-bold underline inline-block align-baseline text-green-700 hover:text-green-800"
                              >
                                {t(
                                  "identity.src.components.login.CreatePassword.termsofUse"
                                )}
                              </a>
                              <span>
                                {t(
                                  "identity.src.components.login.CreatePassword.and"
                                )}
                              </span>
                              <a
                                target="_blank"
                                href={
                                  process.env.REACT_APP_PUBLIC_URL +
                                  "/policies/Privacy%20Policy"
                                }
                                className="cursor-pointer text-xs text-bold underline inline-block align-baseline text-green-700 hover:text-green-800"
                              >
                                {t(
                                  "identity.src.components.login.CreatePassword.PrivacyNotice"
                                )}
                                .
                              </a>
                            </p>
                            <p className="text-xs normalDoubleBottomMargin text-gray-400">
                              {t(
                                "identity.src.components.login.CreatePassword.AllreadyHaveAnAcount?"
                              )}
                              <p
                                onClick={() => props.setStep(1)}
                                className="cursor-pointer text-xs text-bold underline inline-block align-baseline text-blue-700 hover:text-blue-800"
                              >
                                {t(
                                  "identity.src.components.login.CreatePassword.SignIn"
                                )}
                              </p>
                            </p>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  ) : null;
};

export default ChangePassword;

import { useState, useEffect } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const Timer = ({step, handleRequest }: any) => {
  const [timeLeft, setTimeLeft] = useState(30);
  const [timerColor, setTimerColor] = useState("#5c757a");
  const [timerActive, setTimerActive] = useState(false);
  const [iconTimer, setIconTimer] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const {t} = useTranslation()

  useEffect(() => {
    if (step == 5) {
      setTimerActive(true);
    } else {
      setTimerActive(false);
    }
  }, [step]);

  useEffect(() => {
    let intervalId = null as any;
    if (timerActive && timeLeft > 0) {
      intervalId = setInterval(() => {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
        handleRequest(false);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [timerActive, timeLeft]);

  useEffect(() => {
    if (timeLeft === 0) {
      setTimerColor("#037cff");
      setTimerActive(false);
      setIconTimer(true);
    }
  }, [timeLeft]);

  const formatTimeLeft = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleOtp = () => {
    if (timeLeft === 0) {
      handleRequest(true);
      setTimerActive(true);
      setTimeLeft(30);
    }
  };

  return (
    <>
      <div
        onClick={() => handleOtp()}
        className="w-[100%] h-fit flex flex-row gap-x-2 justify-center items-center mt-5 cursor-pointer "
      >
        {/* <img className={`${iconTimer ? "hidden" : ""}`} src="/loading.svg" />
        <img className={`${iconTimer ? "" : "hidden"}`} src="/timerBlue.svg" /> */}
        <span
          style={{ color: timerColor }}
          className="font-[600] text-[14px]"
        >
          {formatTimeLeft()}
        </span>

        <p
          style={{ color: timerColor }}
          className="font-[600] text-[14px] garage-title"
        >
         {t("identity.src.component.timer.toResendTheCode")}
        </p>
      </div>
    </>
  );
};

export default Timer;

import React, { Fragment, useEffect, useState } from "react";
import _ from "lodash";
import {
  AccountClient,
  UpdateUserDto,
  RegisterCommand,
  ResetPasswordCommand,
} from "../../../swagger";
import toast, { Toaster } from "react-hot-toast";
import { axiosInstance } from "../../../services/AxiosInstance";
import { TokenStorage } from "../../../services/token-storage";
import { Link } from "react-router-dom";
import { ErrorMessage, Form, Formik } from "formik";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";
import { useLocation } from "react-router-dom";
import { unescape } from "lodash";
// interface propsType {
//     Check: boolean
//   }

const MainPage = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation("identity");
  const [percent, setPercent] = useState(0);
  const [errorQuery, setErrorQuery] = useState("") as any;
  const [errorsData, setErrorsData] = useState([
    { text: "At least 12 characters", status: 0, id: 1 },
    { text: "Contains a number and symbol", status: 0, id: 2 },
    { text: "Uppercase character eg. A. B. C", status: 0, id: 3 },
  ]);

  let location = useLocation();

  useEffect(() => {
    if (location.search.includes("error")) {
      const equalIndex = location.search.indexOf("=");
      const ampersandIndex = location.search.indexOf("&");

      if (equalIndex !== -1 && ampersandIndex !== -1) {
        const extractedSubstring = location.search.substring(
          equalIndex + 1,
          ampersandIndex
        );
        const decodedSubstring = decodeURIComponent(extractedSubstring);

        const replacedSubstring = decodedSubstring.replace(/%20/g, " ");

        setErrorQuery(replacedSubstring);
      } else {
        setErrorQuery('Both "=" and "&" not found in the input string.');
      }
    }
  }, []);


  //   const checkValidate = (values) => {
  //     let error = {} as any;
  //     if (!values.password || values.password == "") {
  //       error.password = t("identity.profile.enterAPassword");
  //     } else if (!values.repassword || values.repassword == "") {
  //       error.repassword = t("identity.profile.enterYourPasswordAgain");
  //     } else if (values.password !== values.repassword) {
  //       error.repassword = t("identity.profile.notMatching");
  //     }
  //     return error;
  //   };

  const checkValidate = (values) => {
    let error = {} as any;

    let updatedErrors = [...errorsData];

    if (values.password.length == 1) {
      updatedErrors = errorsData.map((error) => ({
        ...error,
        status: 1,
      }));
    }
    if (!values.password || values.password === "") {
      error.password = t("Enter A Password");
      updatedErrors = errorsData.map((error) => ({
        ...error,
        status: 0,
      }));
    }
    if (values.password.length > 12) {
    //   error.password = t("At least 12 characters");
      updatedErrors[0] = {
        text: "At least 12 characters",
        status: 2,
        id: 1,
      };
    }
    if (/\d/.test(values.password) && /[!@#$%^&*]/.test(values.password)) {
    //   error.password = t("Contains a number and symbol");
      updatedErrors[1] = {
        text: "Contains a number and symbol",
        status: 2,
        id: 2,
      };
    }
    if (/[A-Z]/.test(values.password)) {
    //   error.password = t("Uppercase character eg. A. B. C");
      updatedErrors[2] = {
        text: "Uppercase character eg. A. B. C",
        status: 2,
        id: 3,
      };
    }
    if (!values.repassword || values.repassword == "") {
      error.repassword = t("identity.profile.enterYourPasswordAgain");
    }
    if (values.password !== values.repassword) {
      error.repassword = t("identity.profile.notMatching");
    }

    setErrorsData(updatedErrors);
    return error;
  };

  const onSubmit = async (values) => {
    setLoading(true);
    const client = new AccountClient(undefined, axiosInstance);
    try {
      const authResult = new URLSearchParams(window.location.search);
      const redirectUrl = authResult.get("returnUrl");
      const email = authResult.get("email");
      const token = authResult.get("token");

      const res = await client.resetPassword(
        {
          email: email,
          token: token,
          password: values.password,
          isEmail: true,
          userPhoneNumber:{}  ,
        } as ResetPasswordCommand,
        redirectUrl
      );
      if (res?.succeeded) {
        toast.success(
          t("identity.resetPass.yourPasswordWasChangedSuccessfully")
        );
        if (res.redirectUrl && !_.isNull(res.redirectUrl)) {
          window.location.replace(redirectUrl as string);
        }
        setLoading(false);
        // TokenStorage.storeToken(res.data. as string);
        // TokenStorage.storeRefreshToken(res.refresh_token as string);
        // window.location.replace("/");
      } else {
        setLoading(false);
        toast.error(_.first(res.errors) as any);
        // addToast(res.error, { appearance: "error" });
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        t(
          "identity.src.components.login.EnterEmail.somethingUnexpectedHappenedPleaseTryAgainLater"
        )
      );
      // addToast("Server Error Occured", { appearance: "error" });
    }
  };
  return (
    <>
      <Toaster position="top-center" reverseOrder={true}></Toaster>
      <div className="mx-auto grid place-items-center min-h-screen  ">
        <div className="organizer clearfix registerFormContainer rounded overflow-visible lg:shadow-lg p-6">
          <div className="sizeHandler">
            <div className="signTop organizer clearfix">
              <div className="flex gap-x-6 place-items-center organizer loginForm fln m0a grid-6 grid-t-12">
                <div className="w-full px-8 registerImage sm:block hidden border-r-2 border-grey-50 leftFloat centerFlex halfColumn">
                  <div className="centerKeeper organizer">
                    <img src="/img/sign.svg" className="h-80" />
                  </div>
                </div>
                <div className="w-full rightFloat halfColumn leftBorder hv col-auto">
                  <div className="centerKeeper textInCenter organizer flex flex-col px-8 gap-x-6 gap-y-2 text-center font-semibold">
                    <Link to="/">
                      <a>
                        <img
                          src="/img/Voxdash.svg"
                          alt="VoxDash"
                          className="mx-auto h-12 w-150 flex justify-center"
                        />
                      </a>
                    </Link>
                    {/* <h1 className="text-xl normalDoubleBottomMargin text-gray-600">
                      {t(
                        "identity.src.pages.User.ForgetPassword.ResetPassword.CreateNewPassword"
                      )}
                    </h1> */}
                    {errorQuery.length > 0 ? (
                      <></>
                    ) : (
                      <h1 className="text-xl normalDoubleBottomMargin text-gray-600">
                        {t(
                          "identity.src.pages.User.ForgetPassword.ResetPassword.CreateNewPassword"
                        )}
                      </h1>
                    )}
                    <h2 className="text-l normalDoubleBottomMargin text-gray-600">
                      {/* {props.form.email} */}
                    </h2>
                    {/* <div onClick={() => props.setStep(1)}>
                                            <a className="cursor-pointer underline inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800">
                                                Use a different Email 
                                            </a>
                                        </div> */}
                    <Formik
                      onSubmit={onSubmit}
                      initialValues={{}}
                      validate={checkValidate}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                      }) => (
                        <Form
                          onSubmit={handleSubmit}
                          className="organizer fln m0a grid-6 grid-t-12"
                        >
                          <>
                            {errorQuery.length > 0 ? (
                              <p className="text-red-500 text-lg font-semibold italic">{errorQuery}</p>
                            ) : (
                              <div className="flex flex-col gap-y-4 place-content-center">
                                <div>
                                  <div className="relative">
                                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                                      <svg
                                        fill="none"
                                        onClick={() =>
                                          setShowPassword(!showPassword)
                                        }
                                        className={classNames(
                                          "h-3 text-gray-700",
                                          {
                                            hidden: !showPassword,
                                            block: showPassword,
                                          }
                                        )}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 576 512"
                                      >
                                        <path
                                          fill="currentColor"
                                          d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
                                        ></path>
                                      </svg>

                                      <svg
                                        fill="none"
                                        onClick={() =>
                                          setShowPassword(!showPassword)
                                        }
                                        className={classNames(
                                          "h-3 text-gray-700",
                                          {
                                            hidden: showPassword,
                                            block: !showPassword,
                                          }
                                        )}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 640 512"
                                      >
                                        <path
                                          fill="currentColor"
                                          d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"
                                        ></path>
                                      </svg>
                                    </div>
                                    <input
                                      value={values.password}
                                      onChange={handleChange}
                                      name="password"
                                      type={showPassword ? "text" : "password"}
                                      id="email-address-icon"
                                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-5 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                      placeholder="New Password"
                                    />

                                  </div>
                                    {errors.password ? (
                                  // && touched.userLoginEmail
                                  <p className="text-red-500 text-xs italic">
                                    {errors.password.toString()}
                                  </p>
                                ) : null}

                                  <div className="flex flex-col space-y-2 mt-3">
                                    <ProgressBar
                                      percent={percent}
                                      height="4px"
                                      filledBackground={
                                        percent >= 0 && percent < 34
                                          ? "#DC3545"
                                          : percent >= 34 && percent < 67
                                          ? "#FC8A20"
                                          : "#00917C"
                                      }
                                    />
                                    <div className="pt-2 flex flex-col space-y-2">
                                      {errorsData.map((e) => (
                                        <>
                                          <div className="flex flex-row space-x-1 items-center">
                                            <span
                                              className={`  ${
                                                e.status == 0
                                                  ? "icon-Ellipse-2840-Stroke text-[#828A96]"
                                                  : e.status == 1
                                                  ? "icon-wrong text-[#DC3545]"
                                                  : "icon-valid text-[#00917C]"
                                              } `}
                                            ></span>
                                            <div
                                              className={` ${
                                                e.status == 0
                                                  ? "text-[#828A96]"
                                                  : e.status == 1
                                                  ? "text-[#DC3545]"
                                                  : "text-[#00917C]"
                                              } text-sm font-[400]`}
                                            >
                                              {e.text}
                                            </div>
                                          </div>
                                        </>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <div className="relative">
                                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                                    <svg
                                      fill="none"
                                      onClick={() =>
                                        setShowPassword(!showPassword)
                                      }
                                      className={classNames(
                                        "h-3 text-gray-700",
                                        {
                                          hidden: !showPassword,
                                          block: showPassword,
                                        }
                                      )}
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 576 512"
                                    >
                                      <path
                                        fill="currentColor"
                                        d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
                                      ></path>
                                    </svg>

                                    <svg
                                      fill="none"
                                      onClick={() =>
                                        setShowPassword(!showPassword)
                                      }
                                      className={classNames(
                                        "h-3 text-gray-700",
                                        {
                                          hidden: showPassword,
                                          block: !showPassword,
                                        }
                                      )}
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 640 512"
                                    >
                                      <path
                                        fill="currentColor"
                                        d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"
                                      ></path>
                                    </svg>
                                  </div>
                                  <input
                                    value={values.repassword}
                                    onChange={handleChange}
                                    name="repassword"
                                    type={showPassword ? "text" : "password"}
                                    id="email-address-icon"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-5 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder={t(
                                      "identity.profile.reTypePassword"
                                    )}
                                  />

                                </div>
                                  {errors.repassword ? (
                                    // && touched.userLoginEmail
                                    <p className="text-red-500 text-xs italic">
                                      {errors.repassword.toString()}
                                    </p>
                                  ) : null}

                                <button
                                  type="submit"
                                  className="text-white bg-[#00917c] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center block  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                >
                                  {!loading ? (
                                    <Fragment>
                                      {t(
                                        "identity.src.pages.User.ForgetPassword.ResetPassword.Submit"
                                      )}
                                      <svg
                                        className="float-right flex w-5 h-5 ml-2 -mr-1"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                          clipRule="evenodd"
                                        ></path>
                                      </svg>
                                    </Fragment>
                                  ) : (
                                    <Fragment>
                                      <svg
                                        role="status"
                                        className="inline w-4 h-4 mr-3 text-white animate-spin"
                                        viewBox="0 0 100 101"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                          fill="#E5E7EB"
                                        />
                                        <path
                                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                      {t(
                                        "identity.src.pages.User.ForgetPassword.ResetPassword.Loading"
                                      )}
                                    </Fragment>
                                  )}
                                </button>
                              </div>
                            )}
                          </>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainPage;

import { Form, Formik } from "formik";
import _ from "lodash";
import { Fragment, useState, useEffect } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../services/AxiosInstance";
import {
  AccountClient,
  CheckOtpCodeCommand,
  EnableTwoFactorCommand,
  TwoFactorProvider,
  UserPhoneNumber,
  VerifyTwoFactorAuthenticationCodeCommand,
} from "../../swagger";
import { useTranslation } from "react-i18next";
import Timer from "../timer";

type propType = {
  setStep: Function;
  email: string;
  redirectUrl: string;
  provider:TwoFactorProvider;
  forEmailActivation:boolean;
  step:number;
};

const VerifyCode = (props: propType) => {
  const [loading, setLoading] = useState(false);
  const [request, setRequest] = useState(false);
  const [waitForRedirect , setWaitForRedirect] = useState(false)
  const { t } = useTranslation("identity.");

  const onSubmit = async (values) => {
    setLoading(true);
    const accountClient = new AccountClient(undefined, axiosInstance);
    try {
      if(props.forEmailActivation){
        var accountRes = await accountClient.activeUser(props.email , values.code)
        if (accountRes?.succeeded) {
          if (props.redirectUrl && !_.isNull(props.redirectUrl)) {
            toast.success(t("identity.src.components.login.Password.youreLoggedin"));
            setLoading(false);
            setRequest(false);
            setWaitForRedirect(true)
            window.location.replace(props.redirectUrl as string);
          } else {
            setWaitForRedirect(true)
            window.location.replace(process.env.REACT_APP_PUBLIC_URL);
            setLoading(false);
            setRequest(false);
          }
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(_.first(accountRes.errors) as any);
        }
      }else{
        const accountCommand = {
          user: undefined,
          provider: props.provider,
          code: values.code,
        } as VerifyTwoFactorAuthenticationCodeCommand;

        var accountRes = await accountClient.verifyTwoFactorAuthenticationCode(
          accountCommand
        );
        if (accountRes?.succeeded) {
          if (props.redirectUrl && !_.isNull(props.redirectUrl)) {
            toast.success(t("identity.src.components.login.Password.youreLoggedin"));
            setLoading(false);
            setRequest(false);
            window.location.replace(props.redirectUrl as string);
          } else {
            window.location.replace(process.env.REACT_APP_PUBLIC_URL);
            setLoading(false);
            setRequest(false);
          }
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(_.first(accountRes.errors) as any);
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error(t("identity.src.components.login.EnterEmail.somethingUnexpectedHappenedPleaseTryAgainLater"))
      console.log("error of TwoStepVerificationPhoneNumVerify", error);
    }
  };

  const resendCode = async () => {
    const client = new AccountClient(undefined, axiosInstance);
    const command = {
      user: undefined,
      provider: props.provider,
      phoneNumberId: 0,
      password: undefined,
      isEnable: true,
      isResend: true,
    } as EnableTwoFactorCommand;

    try {
      var res = await client.enableTwoFactorAuthentication(command);
      if (res && res.succeeded) {
        toast.success(t("identity.src.components.login.Password.codeSentSuccessfully"));
        props.setStep(4);
      } else {
        toast.error(_.first(res.errors) as any);
      }
    } catch (error) {
      toast.error(t("identity.src.components.login.EnterEmail.somethingUnexpectedHappenedPleaseTryAgainLater"));
    }
  };

  useEffect(() => {
    if (request) {
      resendCode();
    }
  }, [request]);

  const checkValidate = (values) => {
    let error = {} as any;
    if (values.code == "") {
      error.code = t("identity.src.components.login.Password.enterCode")
    }
    return error;
  };

  return (
    <Fragment>
      <div
        onClick={() => props.setStep(1)}
        className="underline cursor-pointer inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
      >
        {t("identity.src.components.login.Password.UseADifferentEmail")}
      </div>
      {!props.forEmailActivation && props.provider != TwoFactorProvider.GoogleAuthenticator ?
        <Timer step={props.step} handleRequest={setRequest} />
      :null}
      <h2 className="text-l normalDoubleBottomMargin text-gray-600">
        {props.email}
      </h2>

      {props.forEmailActivation?
        <div className="text-sm normalDoubleBottomMargin text-gray-600">
          {t('identity.src.components.login.Password.PleaseEnterTheVerificationCodeSentToYourInboxToCompleteTheRegistrationProcess')}
        </div>
      :props.provider == TwoFactorProvider.GoogleAuthenticator?
      <div className="text-sm normalDoubleBottomMargin text-gray-600">
        {t('identity.src.components.login.Password.PleaseEnterTheVerificationCodeFromAuthenticatorApp')}
      </div>
      :props.provider == TwoFactorProvider.PhoneNumber?
      <div className="text-sm normalDoubleBottomMargin text-gray-600">
        {t('identity.src.components.login.Password.PleaseEnterTheVerificationCodeSentToYourPhonenumber')}
      </div>
      :null}
      <Formik
        onSubmit={onSubmit}
        initialValues={{ code: "" }}
        validate={checkValidate}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form
            onSubmit={handleSubmit}
            className="organizer fln m0a grid-6 grid-t-12"
          >
            <div className="flex flex-col gap-y-4 place-content-center">
              <input
                value={values.code}
                onChange={handleChange}
                name="code"
                type={"text"}
                id="email-address-icon"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter code"
              />

              {errors.code ? (
                <p className="text-red-500 text-xs italic">
                  {errors.code.toString()}
                </p>
              ) : null}

              <button
                type="submit"
                className="text-white bg-[#00917c] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center block  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                {!loading ? (
                  <Fragment>
                    {t(
                      "identity.src.components.login.Password.SignInToYourAccount"
                    )}
                    <svg
                      className="float-right flex w-5 h-5 ml-2 -mr-1"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </Fragment>
                ) : (
                  <Fragment>
                    <svg
                      role="status"
                      className="inline w-4 h-4 mr-3 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    {t("identity.src.components.login.VerifyCode.Loading")}
                  </Fragment>
                )}
              </button>
              {waitForRedirect?
                <p className="text-green-500 text-xs italic">
                  {t("identity.src.components.login.VerifyCode.Redirecting")}...
                </p>
              :null}
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default VerifyCode;
